<template>
  <div>
      <h1>{{ $t("fitness.stats") }}</h1>
    <h3 style="margin: 10px">{{ $t("fitness.all_time") }}</h3>

    <!-- All time results -->
    <v-layout
      wrap
      row
      style="margin-left: 0; margin-right: 0; margin-top: 20px"
    >
      <info-tile :name="$t('fitness.all_time_steps')" :data="all_time_steps" icon="shoe-prints"></info-tile>
      <info-tile :name="$t('fitness.all_time_average_steps')" :data="all_time_average_steps" icon="shoe-prints"></info-tile>
      <info-tile :name="$t('fitness.all_time_floors')" :data="all_time_floors" icon="level-up"></info-tile>
      <info-tile :name="$t('fitness.all_time_distance')" :data="all_time_distance" icon="map"></info-tile>
    </v-layout>
  </div>
</template>

<script>
import InfoTile from '../components/fitness/InfoTile.vue';

import { storage } from '../schmucklicloud';
var numeral = require("numeral");

export default {
  components: { InfoTile },
  data() {
    return {
      all_time_steps: 0,
      all_time_average_steps: 0,
      all_time_distance: 0,
      all_time_floors: 0
    };
  },
  mounted() {
    this.loadTotalSteps();
    this.loadTotalAverageSteps();
    this.loadTotalDistance();
    this.loadTotalFloors();
  },
  methods: {
    async loadTotalSteps() {
        var response_all_time_steps = await storage.sum(
        "fitness_daily",
        [
          {
            column: "date",
            operator: "contains",
            value: "-",
          },
        ],
        "steps"
      );

      if (response_all_time_steps.isOK) {
        this.all_time_steps = numeral(response_all_time_steps.data.sum)
          .format("0,000")
          .replace(/,/g, "'");
      }
    },
    async loadTotalAverageSteps() {
        var response_all_time_avg_steps = await storage.avg(
        "fitness_daily",
        [
          {
            column: "date",
            operator: "contains",
            value: "-",
          },
        ],
        "steps"
      );
      if (response_all_time_avg_steps.isOK) {
        this.all_time_average_steps = numeral(
          response_all_time_avg_steps.data.avg
        )
          .format("0,000")
          .replace(/,/g, "'");
      }
    },
    async loadTotalDistance() {
      var response_all_time_distance = await storage.sum(
        "fitness_daily",
        [
          {
            column: "date",
            operator: "contains",
            value: "-",
          },
        ],
        "distance"
      );
      if (response_all_time_distance.isOK) {
        this.all_time_distance = numeral(response_all_time_distance.data.sum)
          .format("0,000.00")
          .replace(/,/g, "'");
      }
    },
    async loadTotalFloors() {
      var response_all_time_distance = await storage.sum(
        "fitness_daily",
        [
          {
            column: "date",
            operator: "contains",
            value: "-",
          },
        ],
        "floors"
      );
      if (response_all_time_distance.isOK) {
        this.all_time_floors = numeral(Math.floor(response_all_time_distance.data.sum))
          .format("0,000")
          .replace(/,/g, "'");
      }
    },
  },
};
</script>

<style>
</style>